.react-datepicker__month-container {
    //background-color: #FFFFFF8F;
}
.react-datepicker__time-list-item {
    //background-color: #FFFFFF8F;
}
.react-datepicker__header {
    width: 100% !important;
}
.react-datepicker__current-month {
    margin: 0px auto;
}
.react-datepicker-time__header {
    margin: 0px auto;
}
.react-datepicker__input-container {
    font-size: 16px;
    max-width: 160px;

    input {
        max-width: 100%;
        min-width: 0 !important;
        border-radius: 3px;
        border: 1px solid #acaaaa;
        font-size: 14px;
        box-shadow: 1px 1px 2px #00000036;
    }
}
.react-datepicker-wrapper {
    width: max-content;
    input {
        width: max-content;
        width: -moz-max-content;
        padding: 5px;
        min-width: 175px;
    }
}

.react-datepicker__current-month {
    height: 30px !important;
}

.datapicker-container {
    display: grid;
    width: 100% !important;
    margin: 5px;
}
.react-datepicker__day-names {
    width: 100% !important;
    background-color: #1f6b63;
}
.react-datepicker__day-name {
    color: #ffffff !important;
}
.react-datepicker-popper {
    //top:-28px !important;
    & > div {
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    }
}
.react-datepicker {
    background-color: #ffffff !important;
    height: max-content;
    display: flex !important;
    border-radius: 10px;

    .react-datepicker__navigation--next--with-time {
        //top:32px;
        position: absolute;
        margin: 0px !important;
        width: 5px !important;
        background-color: #f7f7f700 !important;
        border-left-color: #224f52;
        left: 220px;
    }
    .react-datepicker__navigation--previous {
        //top:32px;
        position: absolute;
        margin: 0px !important;
        width: 5px !important;
        background-color: #f7f7f700 !important;
        border-right-color: #224f52;
    }
    .react-datepicker__triangle {
        visibility: hidden !important;
    }
}
@media all and (max-width: 700px) {
}
.react-datepicker-popper {
    position: absolute !important;
    width: min-content;
    left: -80px !important;
}
.react-datepicker__time-container .react-datepicker__time {
    background: unset !important;
}

.react-datepicker__day--in-range {
    background-color: #216ba5 !important;
    color: #ffffff !important;
}
.react-datepicker__day--in-range:hover {
    background-color: RGBA(33, 107, 165, 0.5) !important;
}
