.dygraph-title {
  text-align: center;
  top:13px!important;
  //margin-top: -30px;
}

.graph-container{
  width: 100%;
  margin: 0 auto;
  box-shadow: 2px 2px 4px 2px #00000033;
  background: #ffffffbb;
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: 400px;
  position: relative;
  padding: 10px;
}

.dygraph-legend
{
  position: unset;
}
.dygraph-ylabel {
  font-size: 15px;
  text-align: center;
  transform: rotate(-90deg);
  margin-left: 11px;
  left: 12px!important;
}
.chart
{

  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  min-width: 100%;
  max-height: 100%;

  div{
    div{
    height: 50px;
      left: -15px;
      //top: 20px
    }
    canvas{
      left: -15px;
      //top: 20px;
    }
  }
}
.chart div{
  margin-top: 0px;
}
.dygraph-legend{
  left: 10px;
  top: -10px;
  position: absolute;
  width: 300px;
  background-color: rgb(255, 255, 255);
  height: auto !important;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2) !important;
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
  transition: all 0.15s ease-in 0s;
}

.graph-container {
  position: inherit;
}

.graph-label {
  position: relative;
  height: max-content;
  width: max-content;
  margin: 0px auto;
}
.dygraph-annotation {
  width: 20px;
  height: 20px;
  margin-left: -13px;
  top:5px;
  position : absolute;
  display:inline-block;
  color: #000000 !important;
}