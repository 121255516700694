.navi-style {
  background-color:  #224f52!important;
  position: unset;
  width: max-content;
  float: left;
  height: auto;
  img{width: 40px;}
}
.navi-item{
  height: auto;
  width: auto;
}

.navi-menu {
  position: unset;
  height: auto;
  div {
    position: relative
  }
}

@media all and (max-width: 700px) {
  .navi-style {
    position: unset;
    height: auto;
  }
  .navi-menu {
    display: none;
    height: unset;
    div {
      position: relative
    }
  }
  .navi-style:hover {
    height: 100% !important;
    .navi-menu{
      visibility: visible;
    }
    div {
      position: relative;
      display: block;
    }
  }
}
.menu-div{
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  min-height: calc( 100vh - 80px);
}
.div-menu-class
{
  //padding: 20px 0px;
  width: 100%;
  position: unset;
  flex: 1;
}

.col{
  height: fit-content;
  margin-top: 10px;
}
.row{
  position: unset;
  overflow: visible;
  margin-left: 0px;
  height: max-content;
  margin-bottom: 10px;
}


