.form_settings {
  div {
    position: relative
  }
  .form-control {
    width: 80%;
    margin: auto;
  }
  span {
    font-size: 20px;
  }
  .form-group {
    margin-bottom: 0px;
  }

}

small {
  height: max-content;
  span {
    color: red !important;
    font-size: 15px !important;
  }
}

.Toastify {
  font-size: 16px;
  height: auto;
  .Toastify__toast-container {
    height: max-content;
    div{height: max-content}
  }
  .Toastify__toast-body {
    position: relative;
  }
  .Toastify__close-button {
    position: relative;
    background-color: unset!important;
  }
  .Toastify__progress-bar
  {
    height: 3px !important;
  }
}
