.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
  
  .button-container .MuiButton-containedPrimary {
    background-color: #39846A;
    margin: 0;
    margin-right: 8px;
    padding: 6px 12px;
    color: white;
  }
  .button-container .MuiButton-containedPrimary:last-child {
    margin-right: 0;
  }

  .date-range-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

 

  .week-days {
    box-shadow: 2px 2px 4px #00000033;
    background-color: #fff;
    width: auto;
    height: 40px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    color: #574F4F;
    font-weight: bold;
    text-align: center;
  }

  
  
  .recharts-wrapper {
    margin: auto; 
    max-width: 95%; 
    background-color: #f5f5f5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  @media (min-width: 1920px) {
    .recharts-wrapper {
      width: 100%;
      max-width: 900px; 
      margin: 0 auto;
    }
  }


  @media (max-width: 600px) {
    .recharts-wrapper {
      max-width: calc(100% - 20px);
    }
  }
  
  .recharts-cartesian-axis-tick-value {
    fill: #333; 
  }
  
  .recharts-tooltip-wrapper {
    background-color: #fff; 
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); 
  }
  