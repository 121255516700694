
.drop-down-sz {
  width: max-content;
  position: relative;
  height: max-content;
  margin-right: 30px;
  img {
    width: 30px;
    height: 30px;
  }
  div {
    position: unset;
  }
  span {
    margin-right: 10px !important;
    width: 100%!important;
  }
  button {
    display: flex;
    width: 102px;
  }
  button:after {
    margin: auto 0 auto 10px;
  }
  .btn{
    width: 100%!important;
    .dropdown-menu
    {
      width: 100%!important;
    }
  }

}

.dropdown {
  margin-right: 0px !important;
}

@media all and (max-width: 700px) {
  .drop-down-sz {
    margin-right: 10px;
    img {
      width: 15px;
      height: 15px;
    }
    button {
      display: flex;
      width: 50px;
    }
  }
  .btn {
    padding: 3px 6px !important;
  }

  .dropdown-menu {

  }
  .div_details {
    span {
      overflow: hidden;
    }
    img {
      width: 30px;
      height: 25px;
      margin: 0px auto !important;
    }
  }
  .show_drop_menu{
  .dropdown-menu {
    width: 51px !important;
    min-width: 3.2rem!important;
  }
}

}

.show_drop_menu {

  .dropdown-menu {
    position: absolute;
    display: unset !important;
    height: max-content;
    width: 102px ;
    background-color: #03584fcc !important;
    border: 1px solid rgba(0, 0, 0, 0.35);
    min-width: 6.4rem;
    .div_details {
      height: max-content;
      padding: 0px 5px;
      display: flex;
      justify-content: space-between;
      width: unset;
      color: #FFFFFF !important;
      span {
        margin: auto 0;
      }
    }
    .div_details:hover {
      background-color: #21252969;
      font-size: 20px;
      cursor: pointer;

      img {
        width: 35px;
        height: 30px;
      }
    }
    img {
      margin: 0 0;
    }

  }
}
drop-down-details
{

}
.drop-down-details:hover {
  background-color: #03584fcc !important;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: #FFFFFF !important;
  cursor: pointer;
}
