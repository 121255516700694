.react-calendar {
  width: 100%;
  max-width: 400px; 
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  padding: 10px;
  margin: auto; 
}
@media (max-width: 600px) {
  .react-calendar {
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.react-calendar-wrapper {
  max-width: none; 
}

@media (min-width: 1920px) {
  .react-calendar-wrapper {
    width: 100%; 
    max-width: 900px;
    margin: 0 auto; 
  }
}
  
  .react-calendar__tile {
    max-height: 100px; 
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .highlight {
    background: #39846A; 
    color: white;
    font-weight: bold;
  }
  
  .react-calendar__tile:hover {
    background-color: #e6e6e6; 
  }
  
  .react-calendar__tile--active {
    background-color: #e7f2be;
    color: black;
  }
  
  .react-calendar__tile--active:hover {
    background-color: #c5e384; 
  }
  
  .react-calendar__navigation button {
    background-color: transparent;
    font-size: 1.2em;
  }
  
  .react-calendar__navigation label {
    font-weight: bold;
    font-size: 1.4em;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    color: #555555;
    font-weight: bold;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f0f0f0;
    color: #39846A; 
  }
  
  .react-calendar__navigation button[disabled] {
    background-color: #f9f9f9;
    color: #dcdcdc;
  }