.div-menu-class {
    .card {
        position: unset;
        min-width: 200px;
        width: 100%;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.15),
            0px 0px 9px rgba(0, 0, 0, 0.1);
        //width: 19rem;
        div {
            position: unset;
            height: -moz-max-content;
            //width: 100%!important;
        }
        button {
            background-color: unset;
        }
    }
}

@media all and (max-width: 700px) {
    .card-body {
        //margin: 0px 0;
        //padding: 5px 0px;
    }
    .card-header {
        p {
            font-size: 25px;
        }
        .id-card {
            font-size: 15px;
        }
    }
    .flex-body {
        flex-wrap: wrap;
    }
    .card-text {
        font-size: 13px !important;
    }
    .btn-primary {
        display: flex;
        justify-content: center;

        span {
            margin: 0 auto;
            font-size: 10px;
        }
    }
    .div_subtitle {
        img {
            width: 20px;
            height: 20px;
        }
    }
    .btn_group {
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        //margin: 0px auto !important;
        //height: max-content!important;
        button {
            margin-bottom: 0px !important;
            width: -webkit-fill-available;
            width: -moz-available;
        }
    }
}

.card-header {
    display: flex;
    justify-content: center;
}

.id-card {
    font-size: 14px;
    margin: 20px 0px 20px 10px;
    color: #00000061;
}

.alarm_img {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
}

.div_subtitle {
    display: flex;
    margin-bottom: 10px;
}

.card-title {
    margin: 10px 0px !important;
}

.btn_group {
    display: flex;
    width: max-content;
    margin: auto;
    height: max-content;
    button {
        margin: 5px;
    }
}

.title_device_card {
    text-align: center;
    color: #ffffff;
    background-color: #32786c78;
    height: max-content;
}
.title_device_card:hover {
    text-decoration: underline;
    cursor: pointer;
}
.btn-group {
    .drop-down-sz {
        button {
            margin: 0px !important;
            width: 150px;
        }
        .dropdown-menu {
            min-width: 150px;
            width: 100% !important;
            max-height: 56px;
            overflow: auto;
            .drop-down-details {
                color: #ffffff !important;
                height: 19px;
            }
        }
        .badge-primary {
            margin: 0px 0px 5px 0px !important;
            width: 150px;
            color: #000;
            background-color: #e4eef094;
            border: solid 2px;
            border-color: #39846a;
            font-size: 20px;
            font-weight: 500;
        }
    }
}

.div_subtitle {
    height: max-content;
    max-height: 35px;
}
.flex-body {
    display: flex;
}

.card-text {
    font-size: 20px;
}
.sign_image {
    height: 50px;
    width: auto;
}
.device-main-view {
    //display: grid;
    grid-template-rows: 50px 60% auto;
    overflow: auto;
}

.custom-select {
    font-size: 15px;
}
.div_subtitle .input-group {
    width: 70px;
    margin-left: auto;
}
.div_subtitle .input-group > input {
    height: 40px;
    margin: auto 0px auto auto;
}
