.view-navbar *{
    box-sizing: border-box;
}

.view-navbar{
    width: 100%;
    padding: 20px 10px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.view-navbar div{
    position: relative;
}

.view-navbar-group{
    display: flex;
    align-items: center;
}

.navbar-breadcrumb-wrapper{
    height: 40px;
}

.breadcrumb.navbar-breadcrumb{
    width: auto;
    background-color: #fff;
    font-size: 16px;
    margin-left: 10px;
}

.breadcrumb.navbar-breadcrumb a.breadcrumb-item{
    text-decoration: none;
    color: #574F4F;
}

.breadcrumb.navbar-breadcrumb a.breadcrumb-item.active{
    color: #9e9d9d;
}

.view-navbar-item{
    position: relative;
    box-shadow: 2px 2px 4px #00000033;
    background-color: #fff;
    width: auto;
    height: 40px;
    padding: 5px 15px !important;
    display: flex;
    align-items: center;
    border-radius: 12px !important;
    color: #574F4F;
    font-weight: bold;
    text-align: center;
}

.back-button{
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    box-shadow: 2px 2px 4px #00000033;
    color: #574F4F;
    transition: .15s all linear;
}

.back-button:hover{
    cursor: pointer;
    background-color: #f8f7f7;
}

.navbar-pen-id{
    margin-right: 10px;
}