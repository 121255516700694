$color-white-opacity: #FFFFFFdd;
$color-card-text: #000000;
$font-card-title: 30px Raleway;
$red-color:#ff0505;

.card {
  height: max-content;
  margin: 0 auto;
  top: 15%;
  width: 400px;
  background-color: $color-white-opacity !important;
  border-radius: 10px !important;
  position: unset;
  box-shadow: 2px 2px 4px 2px #00000033;
  font: $font-card-title;
  color: $color-card-text;

  .card-title {
    margin: 0 auto;
    text-align: center;
    height: min-content;
    margin-top: 30px;
    position: unset;
  }
  .login-form-div {
    position: unset;
    width: 90%;
    height: auto;
    margin: 10px auto 0 auto;
  }
  button {
    background-color: #0C3744 !important;
    margin-bottom: 20px !important;
  }
  .error-login
  {
    border-color: $red-color !important;
  }
  .span-invalid{
    color: $red-color;
    font-size: 15px;
    margin: 10px auto 0px auto;
  }
}

@media all and (max-width: 600px) {
  .card {
    width: 80%;
  }
}

@media all and (max-height: 600px) {
  .card {
    height: 80%;
  }
}
