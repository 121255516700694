@import "main";

.appbar{
  background-color: $color-head !important;
  height: 80px;

  .appbar-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 10px;
  }

  .main_head_group{
    display: flex;
    align-items: center;
  }

  

  .main_head_text {
    right: 0;
    position: absolute;
    font: $font-head;
    color: $color-foot-text;
    height: min-content;
    width: max-content;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    span {
      margin-right: 30px;
    }
  }

  .logout-wrapper {

    span{
      margin-right: 0 !important;
    }

    button{
      margin-right: 15px;
    }

    .logout-icon{
      height: 25px;
      width: 25px;
    }
  }


  .menu-button{
    margin-right: 10px;

    .menu-icon{
      color: #fff;
    }
  }
}

.main_logo {
  background-image: url("../../resources/te_eu_logo_white.svg");
  height: 40px;
  width: 466px;
  max-height: 100%;
  background-repeat: no-repeat;
}