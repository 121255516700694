@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
body {
  margin: 0px;
}

.main {
  min-height: 100vh;
}
.main .main_foot {
  position: relative;
  background-color: #0C3744;
  height: 5%;
  font: 15px Raleway;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px 10%;
}
.main .main_background {
  position: relative;
  background: url(/static/media/background_login.92519a25.png) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%;
  min-height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width: 600px) {
  .main .main_foot {
    font: 10px Raleway;
  }
  .main .main_head {
    height: 30px;
  }
  .main .main_head .logout {
    height: 25px;
    width: 25px;
  }
  .main .main_logo {
    height: 23.75px;
    width: 233px;
  }
}
@media all and (max-width: 800px) {
  .main .main_head span {
    display: none;
  }
}

.logout-message {
  width: 100%;
  position: absolute;
  height: -webkit-max-content;
  height: max-content;
  text-align: center;
  margin-top: 50px;
  color: #37b34a;
  background-color: rgba(55, 179, 74, 0.1803921569);
  font-size: 25px;
  top: 80px;
}

.btn-primary {
  color: #FFF;
  background-color: #419C8D;
  border-color: #82B5AD;
}

.btn-primary:hover {
  color: #FFF;
  background-color: #82B5AD;
  border-color: #82B5AD;
}

btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #439B8D;
}

.center_div {
  margin: auto !important;
}

.warning-label {
  display: block;
  margin-left: 10px;
  margin-top: 4px;
  width: 100%;
}

.settings-container,
.device-statistics-container,
.pens-container,
.buildings-container {
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 20px;
  width: 100% !important;
  margin: 0 !important;
}

.appbar {
  background-color: #32786c !important;
  height: 80px;
}
.appbar .appbar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 10px;
}
.appbar .main_head_group {
  display: flex;
  align-items: center;
}
.appbar .main_head_text {
  right: 0;
  position: absolute;
  font: 30px Raleway;
  color: #FFFFFF;
  height: -webkit-min-content;
  height: min-content;
  width: -webkit-max-content;
  width: max-content;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.appbar .main_head_text span {
  margin-right: 30px;
}
.appbar .logout-wrapper span {
  margin-right: 0 !important;
}
.appbar .logout-wrapper button {
  margin-right: 15px;
}
.appbar .logout-wrapper .logout-icon {
  height: 25px;
  width: 25px;
}
.appbar .menu-button {
  margin-right: 10px;
}
.appbar .menu-button .menu-icon {
  color: #fff;
}

.main_logo {
  background-image: url(/static/media/te_eu_logo_white.1d784dd3.svg);
  height: 40px;
  width: 466px;
  max-height: 100%;
  background-repeat: no-repeat;
}
.opacity-bg {
  position: fixed;
  height: 100vh;
  width: 101vw;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  top: 0;
  left: 0;
}

.loader {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 74px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 10px));
  transform-origin: 0% 0%;
}
.view-navbar * {
  box-sizing: border-box;
}

.view-navbar {
  width: 100%;
  padding: 20px 10px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-navbar div {
  position: relative;
}

.view-navbar-group {
  display: flex;
  align-items: center;
}

.navbar-breadcrumb-wrapper {
  height: 40px;
}

.breadcrumb.navbar-breadcrumb {
  width: auto;
  background-color: #fff;
  font-size: 16px;
  margin-left: 10px;
}

.breadcrumb.navbar-breadcrumb a.breadcrumb-item {
  text-decoration: none;
  color: #574F4F;
}

.breadcrumb.navbar-breadcrumb a.breadcrumb-item.active {
  color: #9e9d9d;
}

.view-navbar-item {
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: auto;
  height: 40px;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  color: #574F4F;
  font-weight: bold;
  text-align: center;
}

.back-button {
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  color: #574F4F;
  transition: 0.15s all linear;
}

.back-button:hover {
  cursor: pointer;
  background-color: #f8f7f7;
}

.navbar-pen-id {
  margin-right: 10px;
}
.react-calendar {
  width: 100%;
  max-width: 400px; 
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  padding: 10px;
  margin: auto; 
}
@media (max-width: 600px) {
  .react-calendar {
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.react-calendar-wrapper {
  max-width: none; 
}

@media (min-width: 1920px) {
  .react-calendar-wrapper {
    width: 100%; 
    max-width: 900px;
    margin: 0 auto; 
  }
}
  
  .react-calendar__tile {
    max-height: 100px; 
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .highlight {
    background: #39846A; 
    color: white;
    font-weight: bold;
  }
  
  .react-calendar__tile:hover {
    background-color: #e6e6e6; 
  }
  
  .react-calendar__tile--active {
    background-color: #e7f2be;
    color: black;
  }
  
  .react-calendar__tile--active:hover {
    background-color: #c5e384; 
  }
  
  .react-calendar__navigation button {
    background-color: transparent;
    font-size: 1.2em;
  }
  
  .react-calendar__navigation label {
    font-weight: bold;
    font-size: 1.4em;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    color: #555555;
    font-weight: bold;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f0f0f0;
    color: #39846A; 
  }
  
  .react-calendar__navigation button[disabled] {
    background-color: #f9f9f9;
    color: #dcdcdc;
  }
.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  grid-gap: 10px; 
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
  
  .button-container .MuiButton-containedPrimary {
    background-color: #39846A;
    margin: 0;
    margin-right: 8px;
    padding: 6px 12px;
    color: white;
  }
  .button-container .MuiButton-containedPrimary:last-child {
    margin-right: 0;
  }

  .date-range-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

 

  .week-days {
    box-shadow: 2px 2px 4px #00000033;
    background-color: #fff;
    width: auto;
    height: 40px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    color: #574F4F;
    font-weight: bold;
    text-align: center;
  }

  
  
  .recharts-wrapper {
    margin: auto; 
    max-width: 95%; 
    background-color: #f5f5f5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  @media (min-width: 1920px) {
    .recharts-wrapper {
      width: 100%;
      max-width: 900px; 
      margin: 0 auto;
    }
  }


  @media (max-width: 600px) {
    .recharts-wrapper {
      max-width: calc(100% - 20px);
    }
  }
  
  .recharts-cartesian-axis-tick-value {
    fill: #333; 
  }
  
  .recharts-tooltip-wrapper {
    background-color: #fff; 
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); 
  }
  
.navi-style {
  background-color: #224f52 !important;
  position: unset;
  width: -webkit-max-content;
  width: max-content;
  float: left;
  height: auto;
}
.navi-style img {
  width: 40px;
}

.navi-item {
  height: auto;
  width: auto;
}

.navi-menu {
  position: unset;
  height: auto;
}
.navi-menu div {
  position: relative;
}

@media all and (max-width: 700px) {
  .navi-style {
    position: unset;
    height: auto;
  }
  .navi-menu {
    display: none;
    height: unset;
  }
  .navi-menu div {
    position: relative;
  }
  .navi-style:hover {
    height: 100% !important;
  }
  .navi-style:hover .navi-menu {
    visibility: visible;
  }
  .navi-style:hover div {
    position: relative;
    display: block;
  }
}
.menu-div {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  min-height: calc(100vh - 80px);
}

.div-menu-class {
  width: 100%;
  position: unset;
  flex: 1 1;
}

.col {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
}

.row {
  position: unset;
  overflow: visible;
  margin-left: 0px;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10px;
}
.div-menu-class .card {
  position: unset;
  min-width: 200px;
  width: 100%;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.15), 0px 0px 9px rgba(0, 0, 0, 0.1);
}
.div-menu-class .card div {
  position: unset;
  height: -moz-max-content;
}
.div-menu-class .card button {
  background-color: unset;
}

@media all and (max-width: 700px) {
  .card-header p {
    font-size: 25px;
  }
  .card-header .id-card {
    font-size: 15px;
  }
  .flex-body {
    flex-wrap: wrap;
  }
  .card-text {
    font-size: 13px !important;
  }
  .btn-primary {
    display: flex;
    justify-content: center;
  }
  .btn-primary span {
    margin: 0 auto;
    font-size: 10px;
  }
  .div_subtitle img {
    width: 20px;
    height: 20px;
  }
  .btn_group {
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .btn_group button {
    margin-bottom: 0px !important;
    width: -webkit-fill-available;
    width: -moz-available;
  }
}
.card-header {
  display: flex;
  justify-content: center;
}

.id-card {
  font-size: 14px;
  margin: 20px 0px 20px 10px;
  color: rgba(0, 0, 0, 0.3803921569);
}

.alarm_img {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
}

.div_subtitle {
  display: flex;
  margin-bottom: 10px;
}

.card-title {
  margin: 10px 0px !important;
}

.btn_group {
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  height: -webkit-max-content;
  height: max-content;
}
.btn_group button {
  margin: 5px;
}

.title_device_card {
  text-align: center;
  color: #ffffff;
  background-color: rgba(50, 120, 108, 0.4705882353);
  height: -webkit-max-content;
  height: max-content;
}

.title_device_card:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn-group .drop-down-sz button {
  margin: 0px !important;
  width: 150px;
}
.btn-group .drop-down-sz .dropdown-menu {
  min-width: 150px;
  width: 100% !important;
  max-height: 56px;
  overflow: auto;
}
.btn-group .drop-down-sz .dropdown-menu .drop-down-details {
  color: #ffffff !important;
  height: 19px;
}
.btn-group .drop-down-sz .badge-primary {
  margin: 0px 0px 5px 0px !important;
  width: 150px;
  color: #000;
  background-color: rgba(228, 238, 240, 0.5803921569);
  border: solid 2px;
  border-color: #39846a;
  font-size: 20px;
  font-weight: 500;
}

.div_subtitle {
  height: -webkit-max-content;
  height: max-content;
  max-height: 35px;
}

.flex-body {
  display: flex;
}

.card-text {
  font-size: 20px;
}

.sign_image {
  height: 50px;
  width: auto;
}

.device-main-view {
  grid-template-rows: 50px 60% auto;
  overflow: auto;
}

.custom-select {
  font-size: 15px;
}

.div_subtitle .input-group {
  width: 70px;
  margin-left: auto;
}

.div_subtitle .input-group > input {
  height: 40px;
  margin: auto 0px auto auto;
}
.card {
  height: -webkit-max-content;
  height: max-content;
  margin: 0 auto;
  top: 15%;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.8666666667) !important;
  border-radius: 10px !important;
  position: unset;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  font: 30px Raleway;
  color: #000000;
}
.card .card-title {
  margin: 0 auto;
  text-align: center;
  height: -webkit-min-content;
  height: min-content;
  margin-top: 30px;
  position: unset;
}
.card .login-form-div {
  position: unset;
  width: 90%;
  height: auto;
  margin: 10px auto 0 auto;
}
.card button {
  background-color: #0C3744 !important;
  margin-bottom: 20px !important;
}
.card .error-login {
  border-color: #ff0505 !important;
}
.card .span-invalid {
  color: #ff0505;
  font-size: 15px;
  margin: 10px auto 0px auto;
}

@media all and (max-width: 600px) {
  .card {
    width: 80%;
  }
}
@media all and (max-height: 600px) {
  .card {
    height: 80%;
  }
}
body {
  margin: 0px;
}

.main {
  min-height: 100vh;
}
.main .main_foot {
  position: relative;
  background-color: #0C3744;
  height: 5%;
  font: 15px Raleway;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px 10%;
}
.main .main_background {
  position: relative;
  background: url(/static/media/background_login.92519a25.png) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%;
  min-height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width: 600px) {
  .main .main_foot {
    font: 10px Raleway;
  }
  .main .main_head {
    height: 30px;
  }
  .main .main_head .logout {
    height: 25px;
    width: 25px;
  }
  .main .main_logo {
    height: 23.75px;
    width: 233px;
  }
}
@media all and (max-width: 800px) {
  .main .main_head span {
    display: none;
  }
}

.logout-message {
  width: 100%;
  position: absolute;
  height: -webkit-max-content;
  height: max-content;
  text-align: center;
  margin-top: 50px;
  color: #37b34a;
  background-color: rgba(55, 179, 74, 0.1803921569);
  font-size: 25px;
  top: 80px;
}

.btn-primary {
  color: #FFF;
  background-color: #419C8D;
  border-color: #82B5AD;
}

.btn-primary:hover {
  color: #FFF;
  background-color: #82B5AD;
  border-color: #82B5AD;
}

btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #439B8D;
}

.center_div {
  margin: auto !important;
}

.warning-label {
  display: block;
  margin-left: 10px;
  margin-top: 4px;
  width: 100%;
}

.settings-container,
.device-statistics-container,
.pens-container,
.buildings-container {
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 20px;
  width: 100% !important;
  margin: 0 !important;
}
.drop-down-sz {
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  height: -webkit-max-content;
  height: max-content;
  margin-right: 30px;
}
.drop-down-sz img {
  width: 30px;
  height: 30px;
}
.drop-down-sz div {
  position: unset;
}
.drop-down-sz span {
  margin-right: 10px !important;
  width: 100% !important;
}
.drop-down-sz button {
  display: flex;
  width: 102px;
}
.drop-down-sz button:after {
  margin: auto 0 auto 10px;
}
.drop-down-sz .btn {
  width: 100% !important;
}
.drop-down-sz .btn .dropdown-menu {
  width: 100% !important;
}

.dropdown {
  margin-right: 0px !important;
}

@media all and (max-width: 700px) {
  .drop-down-sz {
    margin-right: 10px;
  }
  .drop-down-sz img {
    width: 15px;
    height: 15px;
  }
  .drop-down-sz button {
    display: flex;
    width: 50px;
  }
  .btn {
    padding: 3px 6px !important;
  }
  .div_details span {
    overflow: hidden;
  }
  .div_details img {
    width: 30px;
    height: 25px;
    margin: 0px auto !important;
  }
  .show_drop_menu .dropdown-menu {
    width: 51px !important;
    min-width: 3.2rem !important;
  }
}
.show_drop_menu .dropdown-menu {
  position: absolute;
  display: unset !important;
  height: -webkit-max-content;
  height: max-content;
  width: 102px;
  background-color: rgba(3, 88, 79, 0.8) !important;
  border: 1px solid rgba(0, 0, 0, 0.35);
  min-width: 6.4rem;
}
.show_drop_menu .dropdown-menu .div_details {
  height: -webkit-max-content;
  height: max-content;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  width: unset;
  color: #FFFFFF !important;
}
.show_drop_menu .dropdown-menu .div_details span {
  margin: auto 0;
}
.show_drop_menu .dropdown-menu .div_details:hover {
  background-color: rgba(33, 37, 41, 0.4117647059);
  font-size: 20px;
  cursor: pointer;
}
.show_drop_menu .dropdown-menu .div_details:hover img {
  width: 35px;
  height: 30px;
}
.show_drop_menu .dropdown-menu img {
  margin: 0 0;
}

.drop-down-details:hover {
  background-color: rgba(3, 88, 79, 0.8) !important;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: #FFFFFF !important;
  cursor: pointer;
}
.react-datepicker__header {
  width: 100% !important;
}

.react-datepicker__current-month {
  margin: 0px auto;
}

.react-datepicker-time__header {
  margin: 0px auto;
}

.react-datepicker__input-container {
  font-size: 16px;
  max-width: 160px;
}
.react-datepicker__input-container input {
  max-width: 100%;
  min-width: 0 !important;
  border-radius: 3px;
  border: 1px solid #acaaaa;
  font-size: 14px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2117647059);
}

.react-datepicker-wrapper {
  width: -webkit-max-content;
  width: max-content;
}
.react-datepicker-wrapper input {
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
  padding: 5px;
  min-width: 175px;
}

.react-datepicker__current-month {
  height: 30px !important;
}

.datapicker-container {
  display: grid;
  width: 100% !important;
  margin: 5px;
}

.react-datepicker__day-names {
  width: 100% !important;
  background-color: #1f6b63;
}

.react-datepicker__day-name {
  color: #ffffff !important;
}

.react-datepicker-popper > div {
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
}

.react-datepicker {
  background-color: #ffffff !important;
  height: -webkit-max-content;
  height: max-content;
  display: flex !important;
  border-radius: 10px;
}
.react-datepicker .react-datepicker__navigation--next--with-time {
  position: absolute;
  margin: 0px !important;
  width: 5px !important;
  background-color: rgba(247, 247, 247, 0) !important;
  border-left-color: #224f52;
  left: 220px;
}
.react-datepicker .react-datepicker__navigation--previous {
  position: absolute;
  margin: 0px !important;
  width: 5px !important;
  background-color: rgba(247, 247, 247, 0) !important;
  border-right-color: #224f52;
}
.react-datepicker .react-datepicker__triangle {
  visibility: hidden !important;
}

.react-datepicker-popper {
  position: absolute !important;
  width: -webkit-min-content;
  width: min-content;
  left: -80px !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background: unset !important;
}

.react-datepicker__day--in-range {
  background-color: #216ba5 !important;
  color: #ffffff !important;
}

.react-datepicker__day--in-range:hover {
  background-color: RGBA(33, 107, 165, 0.5) !important;
}
.dygraph-title {
  text-align: center;
  top: 13px !important;
}

.graph-container {
  width: 100%;
  margin: 0 auto;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.7333333333);
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: 400px;
  position: relative;
  padding: 10px;
}

.dygraph-legend {
  position: unset;
}

.dygraph-ylabel {
  font-size: 15px;
  text-align: center;
  transform: rotate(-90deg);
  margin-left: 11px;
  left: 12px !important;
}

.chart {
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  min-width: 100%;
  max-height: 100%;
}
.chart div div {
  height: 50px;
  left: -15px;
}
.chart div canvas {
  left: -15px;
}

.chart div {
  margin-top: 0px;
}

.dygraph-legend {
  left: 10px;
  top: -10px;
  position: absolute;
  width: 300px;
  background-color: rgb(255, 255, 255);
  height: auto !important;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2) !important;
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
  transition: all 0.15s ease-in 0s;
}

.graph-container {
  position: inherit;
}

.graph-label {
  position: relative;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  margin: 0px auto;
}

.dygraph-annotation {
  width: 20px;
  height: 20px;
  margin-left: -13px;
  top: 5px;
  position: absolute;
  display: inline-block;
  color: #000000 !important;
}
.form_settings div {
  position: relative;
}
.form_settings .form-control {
  width: 80%;
  margin: auto;
}
.form_settings span {
  font-size: 20px;
}
.form_settings .form-group {
  margin-bottom: 0px;
}

small {
  height: -webkit-max-content;
  height: max-content;
}
small span {
  color: red !important;
  font-size: 15px !important;
}

.Toastify {
  font-size: 16px;
  height: auto;
}
.Toastify .Toastify__toast-container {
  height: -webkit-max-content;
  height: max-content;
}
.Toastify .Toastify__toast-container div {
  height: -webkit-max-content;
  height: max-content;
}
.Toastify .Toastify__toast-body {
  position: relative;
}
.Toastify .Toastify__close-button {
  position: relative;
  background-color: unset !important;
}
.Toastify .Toastify__progress-bar {
  height: 3px !important;
}
.device-main-view {
  height: 100%;
}

.device-card {
  padding: 5px;
}

.enability-label {
  width: 30%;
  font-size: 0.4em;
}

.row {
  width: 100%;
}
