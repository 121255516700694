@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css");

$font-foot: 100% Raleway;
$color-head: #32786c;
$color-foot: #0C3744;
$font-foot: 15px Raleway;
$color-foot-text: #FFFFFF;
$font-foot-min: 10px Raleway;
$font-head: 30px Raleway;
$font-head-min: 13px Raleway;

body {
  margin: 0px;
}

.main {

  min-height: 100vh;

  .main_foot {
    position: relative;
    background-color: $color-foot;
    height: 5%;
    font: $font-foot;
    color: $color-foot-text;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 20px 10%;
  }

  .main_background {
    position: relative;
    background: url("../../resources/background_login.png") no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 100%;
    min-height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @media all and (max-width: 600px) {
    .main_foot {
      font: $font-foot-min;
    }
    .main_head {
      height: 30px;
      .logout {
        height: 25px;
        width: 25px;
      }
    }
    .main_logo {
      height: 23.75px;
      width: 233px;
    }
  }
  @media all and (max-width: 800px) {
    .main_head {
      span {
        display: none;
      }
    }
  }
}

.logout-message {
  width: 100%;
  position: absolute;
  height: max-content;
  text-align: center;
  margin-top: 50px;
  color: #37b34a;
  background-color: #37b34a2e;
  font-size: 25px;
  top: 80px;
}
.btn-primary {
  color: #FFF;
  background-color: #419C8D;
  border-color: #82B5AD;}
.btn-primary:hover {
  color: #FFF;
  background-color: #82B5AD;
  border-color: #82B5AD;
}
btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #439B8D;
}
.center_div{
  margin: auto!important;
}

.warning-label{
  display: block;
  margin-left: 10px;
  margin-top: 4px;
  width: 100%;
}

.settings-container,
.device-statistics-container,
.pens-container,
.buildings-container{
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 20px;
  width: 100% !important;
  margin: 0 !important;
}