.opacity-bg {
  position: fixed;
  height: 100vh;
  width: 101vw;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  top: 0;
  left: 0;
}

.loader{
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner{
  width: 74px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc( -50% + 10px ));
  transform-origin: 0% 0%;
}