.device-main-view{
    height: 100%;
}

.device-card{
    padding: 5px;
}

.enability-label{
    width: 30%;
    font-size: .4em;
}

.row{
    width: 100%;
}